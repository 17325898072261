import http from "@/utils/http";

// 倒计时初始话时间
let timer = 60;
// 定时器
let inter;
// 动态js
let decryptJs = "";

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 混淆码
 * @param {string} d
 */
const decryptJsHandle = d => {
  // 第一步：匹配加载的页面中是否含有js
  const regDetectJs = /<script(.| )*?>(.| | )*?<\/script>/gi;
  const jsContained = decryptJs.match(regDetectJs);
  // 第二步：如果包含js，则一段一段的取出js再加载执行
  if (jsContained) {
    // 分段取出js正则
    const regGetJS = /<script(.| )*?>((.| | )*)?<\/script>/im;
    // 按顺序分段执行js
    const jsNums = jsContained.length;
    for (let i = 0; i < jsNums; i++) {
      const jsSection = jsContained[i].match(regGetJS);
      if (jsSection[2]) {
        if (window.execScript) {
          // 给IE的特殊待遇
          window.execScript(jsSection[2]);
        } else {
          // 给其他大部分浏览器用的
          window.eval(jsSection[2]);
        }
      }
    }
  }
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 第二步 获取密匙
 * @param {object} v
 * @param {string} v.phone 手机号码
 * @param {number} v.type 发送类型; 0=登录，1=注册，2=找回密码，3=修改手机号,4=绑定手机号，6=修改邮箱或者验证原手机号
 * @param {func} cb 成功的回调函数
 * @param {func} errcb 失败的回调函数
 */
export const getPreKey = (k, cb, errcb) => {
  http
    .get("/edge/captcha/key/js")
    .then(res => {
      decryptJs = res.data.decryptJs;
      decryptJsHandle(decryptJs);

      // const pKey = {
      //   type: k.type,
      //   length: 8,
      //   decryptJs: encodeURIComponent(decryptJs)
      // };
      // if (k.phone) {
      //   pKey.phone = k.phone;
      // }

      let paramStr = '?type='+k.type+'&length=8&decryptJs='+encodeURIComponent(decryptJs);
      if (k.phone) {
        paramStr += '&phone=' + k.phone;
      }
      http
        .get("/edge/captcha/key"+paramStr)
        .then(res => {
          const preCode = jsDecrypt(8, res.data.accessKey);
          // 短信验证码获取
          if (k.phone) {
            getCode(
              {
                preCode: preCode,
                phone: k.phone,
                type: k.type
              },
              cb,
              errcb
            );
          }
        })
        .catch(err => {
          errcb();
        });
    })
    .catch(err => {
      errcb();
    });
};

/**
 * @author: wangjing
 * @update: 20191030
 * @func
 * @description: 第三步 获取短信验证码
 * @param {object} k
 * @param {string} k.preCode 混淆代码返回的验证码
 * @param {string} k.phone 手机号码
 * @param {number} k.type 发送类型; 0=登录，1=注册，2=找回密码，3=修改手机号,4=绑定手机号，6=修改邮箱或者验证原手机号
 * @param {func} cb 成功的回调函数
 * @param {func} errcb 失败的回调函数
 */
const getCode = (k, cb, errcb) => {
  http
    .get(`/edge/sms/code?preCode=${k.preCode}&phone=${k.phone}&type=${k.type}`)
    .then(res => {
      if (cb) {
        cb({
          verifyCode: res.data.code,
          preCode: k.preCode
        });
      }
    })
    .catch(err => {
      errcb();
    });
};
